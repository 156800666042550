import { APP_URL } from "App";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { CONTENT_FOLDER_ID } from "utils/contentUtils";

const dataContext = createContext();

export const DataProvider = ({ children }) => {
  const [data, setData] = useState({});
  const isInitialMount = useRef(true);

  const getData = useCallback(() => {
    fetch(`${APP_URL}/path/${CONTENT_FOLDER_ID}/list`)
      .then((res) => res.json())
      .then((data) => {
        const result = data.result.map((l) => {
          if (l.type === "raster") {
            l.raster.styles = l.raster.styles.sort(function (a, b) {
              return b.name.localeCompare(a.name);
            });
            l.raster.styles = l.raster.styles.map((s) => {
              if (s.parameters.transitionPoints) {
                s.parameters.transitionPoints =
                  s.parameters.transitionPoints.sort(function (a, b) {
                    return a.value - b.value;
                  });
              }
              return s;
            });
            l.raster.styles.push({
              method: "dynamicStyle",
              name: "Dynamische stijl",
            });
            console.log(l.raster.styles);
            l.raster.styles = l.raster.styles.filter(
              (x) => x.method !== "hillShade"
            );
          }
          return l;
        });
        setData(result?.reduce((pv, cv) => ({ ...pv, [cv?.id]: cv }), {}));
      })
      .catch((err) => console.error(err));
  }, []);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      getData();
    }
  }, [getData]);

  return <dataContext.Provider value={data}>{children}</dataContext.Provider>;
};

export const useData = () => {
  const context = useContext(dataContext);

  if (context === undefined) {
    throw new Error("useData must be used within a DataProvider");
  }
  return context;
};
